@tailwind base;
@tailwind components;

.bg-bunting {
  background-image: url("/public/bunting_combined.png");
  background-color: #fdf1c8;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.semi-transparent {
  background-color: rgba(255, 255, 255, 0.5);
  /* RGB for white is (255, 255, 255) and 0.5 is the opacity */
}

@tailwind utilities;
